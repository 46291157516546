import { SurveyItem } from "@careerinsight/applib-common/entity";
import React from "react";

/**
 * Runtime settings which would be used by default, unless they are modified by a react-survey instance
 */
export const appSettings: {
  surveyApi: string;
  showAnswers: boolean;

  /**
   * Project instances can optionally implement this provider, in the case that they have some of their own question widgets
   */
  getWidget?: (item: SurveyItem) => React.JSX.Element | undefined;
} = {
  surveyApi: "/v1",
  showAnswers: false,
};
