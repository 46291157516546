/**
 * Returns a copy of 'array', with the items shuffled
 * Taken from https://stackoverflow.com/a/2450976/226513
 */
export const shuffle = <T>(array: T[]) => {
	const outputArray = [...array];
	let currentIndex = outputArray.length,
		randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex > 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[outputArray[currentIndex], outputArray[randomIndex]] = [outputArray[randomIndex]!, outputArray[currentIndex]!];
	}

	return outputArray;
};

/**
 * Given the error response of a fetch invocation, this function returns a string
 */
export const getErrorMessage = (err: any): string => {
	if (!err) return "";
	if (err.message) return err.message;
	return JSON.stringify(err);
};

/**
 * Sets a query string parameter in the URL
 * @param name
 * @param value
 * @param replace if set to false, then this action will add to the browser history - i.e. it will go forwards one page,
 * and you'll be able to press the browser back button.  If set to true, then the URL will be updated without affecting
 * the browser history.
 */
export function setQueryParameter(name: string, value: string, replace = false) {
	const searchParams = new URLSearchParams(window.location.search);
	searchParams.set(name, value);
	const newRelativeUrl = window.location.pathname + "?" + searchParams.toString();
	if (replace) window.history.replaceState({ name: value }, "", newRelativeUrl);
	else window.history.pushState({ name: value }, "", newRelativeUrl);
}
