import { RespondentAttribute } from "./entity";

/**
 * The name which appears when registering an MFA device
 */
export const mfaIssuer = "Career Insight";

/**
 * A regex which matches with the password complexity requirements imposed by Amazon Cognito
 */
export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]).{8,}$/;

/**
 * A regex for valid emails
 */
export const emailRegex = /[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$/;

/**
 * A regex for valid UK post codes
 */
export const postCodeRegex =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

/**
 * A regex for Date.toISOString()
 * '2022-12-16T18:29:59.831Z'
 * '1970-01-01T00:00:00.000Z'
 */
export const toISOStringRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/;

/**
 * Parses an object in AWS attribute format
 *
 * @param arr the object in AWS format
 * @param key the attribute we'd like to retrieve
 * @return the value of the attribute, or undefined if it doesn't exist
 */
export function getAwsAttribute(arr: { Name?: string; Value?: string }[], key: string) {
  for (let item of arr) {
    if (item.Name === key) return item.Value;
  }

  return undefined;
}

/**
 * Converts a freeform string into a PROJECT_CODE
 */
export function createCodeFromLabel(label: string, maxLength = 16): string {
  if (!label) return "";
  label = label.replace(/([a-z])([A-Z])/g, "$1 $2");

  const fullCode = label.replace(/\W+/g, "_").toUpperCase();

  if (fullCode.length <= maxLength) return fullCode;

  const words = fullCode.split("_");
  let shortCode = "";

  for (const word of words) {
    if (shortCode.length === 0) {
      shortCode = word;
    } else if (shortCode.length < 8) {
      shortCode += "_" + word;
    } else {
      if (shortCode.length + word.length >= maxLength) return shortCode;
      shortCode += "_" + word;
    }
  }

  return fullCode;
}

/**
 * Returns str if it's truthy, an empty string otherwise
 */
export function nullToEmpty(str: string) {
  return str ? str : "";
}

/**
 * Returns true if 'value' is a valid JavaScript Date object
 */
export function isValidDate(value: any): boolean {
  if (Object.prototype.toString.call(value) === "[object Date]") {
    if (!isNaN(value.getTime())) return true;
  }

  if (typeof value === "string" && value.length > 7) {
    try {
      const d = new Date(value);
      return !isNaN(d.getTime());
    } catch (e) {
      return false;
    }
  }

  return false;
}

/**
 * Returns true if 'value' is either a valid JavaScript date object, or
 * is a string which conforms to the format output by date.toISOString()
 * and which can be used to construct a new date
 */
export function isValidDateString(value: any): boolean {
  if (isValidDate(value)) return true;
  if (typeof value !== "string") return false;

  // Is it in date.toISOString() format?
  if (!toISOStringRegex.test(value)) return false;

  // Can we create a valid JavaScript Date object from it?
  return !isNaN(new Date(value).getTime());
}

/**
 * Returns true if 'value' is a valid boolean
 */
export function isValidBoolean(value: any): boolean {
  return typeof value === "boolean";
  // return value && ["true", "false"].includes(value.trim().toLowerCase());
}

/**
 * Returns true if 'value' is a valid post code
 */
export function isValidPostcode(value: any): boolean {
  if (!value || typeof value !== "string") return false;
  const matches = value.match(postCodeRegex);
  return !!matches;
}

/**
 * Returns true if 'value' is a valid email address
 */
export function isValidEmail(value: any): boolean {
  return value && value.match(emailRegex);
}

/**
 * Returns true if 'value' is populated
 */
export function isPopulated(value: any): boolean {
  if (value === false) return true;
  return !!value;
}

/**
 * Returns a string representation of some HTML
 */
export function htmlToText(html: unknown): string {
  if (!html) return "";
  return (html as string).replace(/<[^>]*>/g, "");
}

/**
 * validateSpreadsheetCell
 * @param columnDef the Respondent attribute definition
 * @param cellValue the cell value under consideration
 * @return null if there were no validation errors, a descriptive message otherwise
 */
export function validateSpreadsheetCell(columnDef: RespondentAttribute, cellValue: any): string | null {
  if (!columnDef.isRequired && !isPopulated(cellValue)) {
    // A non-required cell value was missing, which is OK
    return null;
  } else if (columnDef.isRequired && !isPopulated(cellValue)) {
    return `${columnDef.attributeName} is required, but is missing.`;
  } else {
    const dataType = columnDef.dataType;

    if (dataType === "date" && !isValidDateString(cellValue)) {
      return `${columnDef.attributeName} is not a valid date.`;
    } else if (dataType === "boolean" && !isValidBoolean(cellValue)) {
      return `${columnDef.attributeName} should be TRUE or FALSE.`;
    } else if (dataType === "postcode" && !isValidPostcode(cellValue)) {
      return `${columnDef.attributeName} is not a valid post code.`;
    } else if (dataType === "email" && !isValidEmail(cellValue)) {
      return `${columnDef.attributeName} is not a valid email address.`;
    } else if (dataType === "integer" && isNaN(cellValue)) {
      return `${columnDef.attributeName} is not a valid integer.`;
    }
  }

  return null;
}

export function formatDate(date: Date) {
  return date
    .toLocaleString("en-GB", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
    .replace(/ /g, "-");
}
