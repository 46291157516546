import React from "react";
import { SurveyContext } from "@careerinsight/applib-react";
import { MajorMinorSet } from "@careerinsight/ppg-project-layer";

/**
 * Collapsible set of related MajorMinorFactor items
 */
export const MajorMinorSetWidget: React.FC<{ question: MajorMinorSet }> = ({ question }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [contentHeight, setContentHeight] = React.useState(0);
  const [maxHeight, setMaxHeight] = React.useState(0);
  const { answers, setAnswer } = React.useContext(SurveyContext);
  const contentRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    // Get the max height of the list of major/minor factors
    // This is used for the expand/collapse animation
    setContentHeight(contentRef.current!.offsetHeight);

    // Set the MajorMinorSetWidget to expanded, as per any existing answer
    if (answers[question.questionKey]) {
      setExpanded(true);
      setMaxHeight(contentRef.current!.offsetHeight);
    }
  }, []);

  // Expand/collapse MajorMinorSetWidget
  const toggle = () => {
    const newState = !expanded;
    setExpanded(newState);
    setMaxHeight(newState ? contentHeight : 0);
    setAnswer(question, newState);
  };

  return (
    <li className="block">
      {/* Header */}
      <div className="border rounded-lg">
        <div
          className={
            "flex cursor-pointer rounded-lg hover:bg-secondary-light " + (expanded ? "bg-secondary-light" : "")
          }
          onClick={toggle}
        >
          {/* Title */}
          <div className="grow p-3">
            <div className="text-xl font-bold">{question.label}</div>
            <div>{question.subheading}</div>
          </div>

          {/* Chevron */}
          <div className="px-4 flex flex-col justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 16 16"
              className={"chevron " + (expanded ? "chevron-rotated" : "")}
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 14l6-6-6-6"
              ></path>
            </svg>
          </div>
        </div>

        {/* Collapsible body */}
        <div className="expandable" style={{ maxHeight }}>
          <div className="p-3" ref={contentRef}>
            {/* Info alert */}
            <div className="border border-info-border rounded-md mt-2 mb-3 p-2 text-info-dark bg-info-light flex">
              <div className="self-center">
                <svg id="info-outline" viewBox="-1 -1 18 18" width="22px">
                  <path d="m8.93 6.59-1 4.7c-.07.34.03.54.3.54.2 0 .5-.07.69-.25l-.09.42c-.29.34-.92.6-1.46.6-.7 0-1-.43-.81-1.32L7.3 7.8c.06-.3 0-.4-.29-.47l-.45-.08.08-.38 2.3-.3ZM8 5.5c-1.33 0-1.33-2 0-2s1.33 2 0 2Z"></path>
                  <ellipse
                    cx="8"
                    cy="8.01"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    rx="7.85"
                    ry="7.86"
                  ></ellipse>
                </svg>
              </div>
              <div className="self-center ps-2">Select the factors relevant to you</div>
            </div>

            {/* Iterate over each MajorMinorFactor */}
            {question.surveyItems.map((item) => (
              <div key={item.uniqueId} className="sm:flex items-center py-2">
                {/* Label */}
                <label className="grow pr-3 font-semibold block mb-2 sm:mb-0">{item.label}</label>

                {/* Buttons */}
                <div className="inline-flex rounded-md shadow-sm w-full sm:w-auto" role="group">
                  <button
                    type="button"
                    className={`grow px-4 py-2 text-sm font-medium border rounded-s-lg ${
                      answers[item.questionKey] === "major" ? "bg-primary" : "hover:bg-secondary-light"
                    }`}
                    onClick={() => setAnswer(item, "major")}
                  >
                    <span className={answers[item.questionKey] === "major" ? "text-light" : ""}>Major</span>
                  </button>

                  <button
                    type="button"
                    className={`grow px-4 py-2 text-sm font-medium border-y ${
                      answers[item.questionKey] === "minor" ? "bg-primary" : "hover:bg-secondary-light"
                    }`}
                    onClick={() => setAnswer(item, "minor")}
                  >
                    <span className={answers[item.questionKey] === "minor" ? "text-light" : ""}>Minor</span>
                  </button>

                  <button
                    type="button"
                    className="p-2 text-sm font-medium border rounded-e-lg hover:bg-secondary-light"
                    onClick={() => setAnswer(item, "")}
                  >
                    <div className="text-secondary">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18">
                        <path
                          fill="currentColor"
                          d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </li>
  );
};
