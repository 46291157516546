import React from "react";

export function SurveyHeader() {
  return (
    <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder sm:px-4 pt-3 pb-4 ">
      <div className="flex items-end text-primary">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="19 7 498 71" width="260" role="img" style={{position: "relative", top: "2px"}}
             aria-label="Career Insight logo">
          <title>Career Insight logo</title>
          <path fill="currentColor"
                d="m115.93 50.7 5.7.75q-.93 5.9-4.8 9.24-3.82 3.32-9.43 3.32-7.02 0-11.3-4.57-4.26-4.61-4.26-13.18 0-5.55 1.84-9.7 1.83-4.16 5.57-6.22 3.77-2.1 8.19-2.1 5.57 0 9.12 2.84 3.54 2.8 4.54 7.99l-5.64.87q-.8-3.45-2.87-5.19-2.03-1.74-4.93-1.74-4.38 0-7.12 3.16-2.74 3.13-2.74 9.93 0 6.9 2.64 10.02 2.64 3.12 6.89 3.12 3.42 0 5.7-2.1 2.3-2.09 2.9-6.44Zm34.98 8.32q-3.22 2.74-6.22 3.87-2.96 1.12-6.38 1.12-5.64 0-8.67-2.74-3.03-2.77-3.03-7.05 0-2.52 1.13-4.58 1.16-2.1 3-3.35 1.87-1.26 4.19-1.9 1.7-.45 5.16-.87 7.02-.84 10.34-2l.03-1.52q0-3.54-1.64-4.99-2.22-1.97-6.6-1.97-4.1 0-6.07 1.45-1.93 1.42-2.86 5.06l-5.68-.77q.78-3.64 2.55-5.87 1.77-2.25 5.12-3.44 3.37-1.23 7.77-1.23 4.38 0 7.12 1.03t4.03 2.61q1.29 1.55 1.8 3.94.3 1.48.3 5.34v7.74q0 8.09.35 10.25.39 2.12 1.48 4.09h-6.06q-.9-1.8-1.16-4.22Zm-.48-12.96q-3.16 1.3-9.47 2.2-3.58.51-5.06 1.15-1.49.65-2.3 1.9-.8 1.23-.8 2.75 0 2.32 1.74 3.86 1.77 1.55 5.16 1.55 3.35 0 5.96-1.45 2.61-1.48 3.84-4.03.93-1.96.93-5.8Zm16.77 17.18V29.02h5.22v5.18q2-3.64 3.68-4.8 1.7-1.16 3.73-1.16 2.94 0 5.97 1.87l-2 5.38q-2.13-1.25-4.26-1.25-1.9 0-3.41 1.16-1.52 1.12-2.16 3.15-.97 3.1-.97 6.77v17.92Zm47.45-11.02 6 .74q-1.42 5.25-5.25 8.15-3.84 2.9-9.8 2.9-7.51 0-11.93-4.6-4.38-4.65-4.38-13 0-8.63 4.45-13.4t11.54-4.77q6.86 0 11.21 4.67 4.35 4.68 4.35 13.15 0 .52-.03 1.55h-25.52q.32 5.64 3.19 8.64 2.86 3 7.15 3 3.2 0 5.45-1.68t3.57-5.35Zm-19.04-9.38h19.1q-.38-4.32-2.18-6.48-2.77-3.35-7.19-3.35-4 0-6.73 2.68-2.71 2.67-3 7.15Zm57.73 9.38 6 .74q-1.42 5.25-5.26 8.15-3.83 2.9-9.8 2.9-7.5 0-11.92-4.6-4.38-4.65-4.38-13 0-8.63 4.44-13.4 4.45-4.77 11.54-4.77 6.87 0 11.22 4.67 4.35 4.68 4.35 13.15 0 .52-.03 1.55h-25.53q.32 5.64 3.2 8.64 2.86 3 7.15 3 3.19 0 5.44-1.68 2.26-1.68 3.58-5.35Zm-19.05-9.38h19.11q-.38-4.32-2.19-6.48-2.77-3.35-7.18-3.35-4 0-6.74 2.68-2.7 2.67-3 7.15Zm34.24 20.4V29.02h5.22v5.18q2-3.64 3.68-4.8 1.7-1.16 3.74-1.16 2.93 0 5.96 1.87l-2 5.38q-2.13-1.25-4.25-1.25-1.9 0-3.42 1.16-1.52 1.12-2.16 3.15-.97 3.1-.97 6.77v17.92Z" />
          <path fill="currentColor"
                d="M292.95 24.37V16H302v8.37Zm0 38.87V29.02H302v34.22Zm51.45 0h-9.06V45.77q0-5.54-.58-7.15-.58-1.64-1.9-2.55-1.3-.9-3.13-.9-2.35 0-4.22 1.29-1.87 1.29-2.58 3.42-.67 2.12-.67 7.86v15.5h-9.06V29.02h8.41v5.02q4.48-5.8 11.28-5.8 3 0 5.48 1.1 2.48 1.06 3.74 2.74 1.29 1.67 1.77 3.8.52 2.13.52 6.09Zm7.97-9.76 9.09-1.39q.58 2.64 2.35 4.03 1.77 1.35 4.96 1.35 3.52 0 5.29-1.29 1.19-.9 1.19-2.41 0-1.04-.64-1.71-.68-.65-3.03-1.2-10.96-2.41-13.9-4.41-4.05-2.77-4.05-7.7 0-4.45 3.5-7.48 3.52-3.03 10.91-3.03 7.03 0 10.44 2.29 3.42 2.29 4.7 6.77l-8.53 1.58q-.55-2-2.1-3.06-1.51-1.07-4.35-1.07-3.57 0-5.12 1-1.03.71-1.03 1.84 0 .96.9 1.64 1.22.9 8.44 2.55 7.25 1.64 10.12 4.03 2.84 2.41 2.84 6.73 0 4.7-3.93 8.09-3.95 3.38-11.65 3.38-7 0-11.08-2.83-4.06-2.84-5.32-7.7Zm41.88-29.1V16h9.05v8.37Zm0 38.86V29.02h9.05v34.22Zm19.47 2.26 10.35 1.25q.26 1.8 1.2 2.48 1.28.97 4.05.97 3.55 0 5.32-1.06 1.2-.71 1.8-2.3.42-1.12.42-4.15v-5q-4.06 5.55-10.24 5.55-6.9 0-10.93-5.83-3.16-4.61-3.16-11.48 0-8.6 4.13-13.14 4.15-4.55 10.31-4.55 6.35 0 10.47 5.58v-4.8h8.48v30.7q0 6.07-1 9.06-1 3-2.8 4.7-1.8 1.72-4.84 2.68-3 .97-7.6.97-8.7 0-12.35-3-3.64-2.96-3.64-7.54 0-.45.03-1.1Zm8.1-20.08q0 5.45 2.09 8 2.13 2.5 5.22 2.5 3.32 0 5.6-2.57 2.3-2.61 2.3-7.7 0-5.32-2.2-7.9-2.19-2.58-5.54-2.58-3.25 0-5.38 2.55-2.1 2.51-2.1 7.7ZM465.88 16v17.37q4.38-5.13 10.48-5.13 3.12 0 5.63 1.16 2.52 1.16 3.78 2.97 1.28 1.8 1.74 4 .48 2.18.48 6.8v20.07h-9.06V45.16q0-5.38-.51-6.83-.52-1.45-1.84-2.29-1.29-.87-3.25-.87-2.26 0-4.03 1.1-1.77 1.1-2.61 3.32-.8 2.19-.8 6.5v17.15h-9.06V16Zm48.97 13.02v7.21h-6.19v13.8q0 4.19.16 4.9.2.67.8 1.12.65.45 1.55.45 1.26 0 3.65-.87l.77 7.03q-3.16 1.35-7.16 1.35-2.44 0-4.41-.8-1.97-.84-2.9-2.13-.9-1.32-1.26-3.54-.29-1.58-.29-6.38V36.23h-4.15v-7.21h4.15v-6.8l9.09-5.29v12.09Z" />
          <circle stroke="currentColor" cx="52.56" cy="46.54" r="28.68" fill="none" strokeWidth="4" />
          <path fill="#6c757d"
                d="M72.67 46.61 46.05 31.24v7.61h-.28c-8.66 0-15.67-6.87-15.67-15.34S37.12 8.17 45.77 8.17c.28 0 .55 0 .83.02v-.18c-.86-.09-1.74-.15-2.63-.15-13.11 0-23.74 10.49-23.74 23.42S30.86 54.7 43.97 54.7c.7 0 1.39-.04 2.07-.1v7.37l26.62-15.39Z" />
        </svg>
      </div>
    </div>
  );
}
