import React from "react";

export const SurveyFinished = () => {
  return (
    <div>
      <h1 className="text-primary text-3xl font-bold">Complete</h1>
      <p>You may now close this browser window</p>
    </div>
  );
};
