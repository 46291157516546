import React from "react";
import { SurveyItem } from "@careerinsight/applib-common/entity";
import { HtmlWidget } from "./HtmlWidget";
import { NetPromoterWidget } from "./NetPromoterWidget";
import { SelectWidget } from "./SelectWidget";
import { TextAreaWidget } from "./TextAreaWidget";
import { StringWidget } from "./StringWidget";
import { ConditionalWidget } from "./ConditionalWidget";
import { UnknownWidget } from "./UnknownWidget";

/**
 * Returns the widget component corresponding to the item type, or the UnknownWidget if none can be found
 */
export function getStandardWidget(item: SurveyItem) {
  if (item.type === "html") return <HtmlWidget item={item} />;
  if (item.type === "netPromoter") return <NetPromoterWidget question={item} />;
  if (item.type === "select") return <SelectWidget question={item} />;
  if (item.type === "textarea") return <TextAreaWidget question={item} />;
  if (item.type === "string") return <StringWidget question={item} />;
  if (item.type === "conditional") return <ConditionalWidget item={item} />;
  return <UnknownWidget item={item} />;
}
