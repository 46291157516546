import {
  PdfCode,
  RespondentImportBatch,
  RespondentImportItem,
  SurveyCohort,
  SurveyInstance,
  SurveyProject
} from "./entity";
import { DefaultEmailRenderer } from "./DefaultEmailRenderer.js";
import { CommonUtil } from "./CommonUtil";

export type EmailDates = {
  /**
   * The date the invite email should be sent in ISO format, or undefined if no invite email should be sent
   */
  inviteDate: string | undefined;

  /**
   * The date the first reminder should be sent in ISO format, or undefined if no reminder should be sent
   */
  reminder1Date: string | undefined;

  /**
   * The date the second reminder should be sent in ISO format, or undefined if no reminder should be sent
   */
  reminder2Date: string | undefined;
};

/**
 * A set of descriptive messages of how this row was processed
 */
export type AppMessage = {
  label: string;
  severity: "DEBUG" | "INFO" | "WARN" | "ERROR" | "CRITICAL";
};

/**
 * Everything related to an inbound Respondent. Used to make business-logic decisions
 */
export type ImportItemContext = {
  /**
   * The SurveyProject to which this import belongs
   */
  surveyProject: SurveyProject;
  /**
   * The SurveyCohort to which this import belongs
   */
  surveyCohort: SurveyCohort;
  /**
   * The RespondentImportBatch which contains the item to import
   */
  respondentImportBatch: RespondentImportBatch;
  /**
   * The RespondentImportItem which is being imported
   */
  respondentImportItem: RespondentImportItem;
  /**
   * If the import turns out to be an update, then this is the existing SurveyInstance that we're updating
   */
  surveyInstance?: SurveyInstance;
};

export class DefaultProjectLayer {
  /**
   * Given the import item and some context, this function computes the invite and reminder dates for a Survey
   *
   * The default implementation is for the invite to be sent out immediately, and for the reminders to be sent out 7
   * and 11 days later
   */
  getEmailDates(ctx: ImportItemContext): EmailDates {
    const DAYS = 24 * 60 * 60 * 1000;
    const inviteDate = ctx.surveyInstance ? ctx.surveyInstance.createdDate : ctx.respondentImportBatch.date;
    const reminder1Date = new Date(new Date(inviteDate).getTime() + 7 * DAYS).toISOString();
    const reminder2Date = new Date(new Date(inviteDate).getTime() + 11 * DAYS).toISOString();
    const response = { inviteDate, reminder1Date, reminder2Date };
    CommonUtil.debug("DefaultProjectLayer.getEmailDates", response);
    return response;
  }

  /**
   * Given the import item and some context, is it OK to import the Respondent (thereby creating a new Survey)?
   */
  validateRespondent(ctx: ImportItemContext): { success: boolean; messages: Array<AppMessage> } {
    const response = { success: true, messages: [] };
    CommonUtil.debug("DefaultProjectLayer.validateRespondent", response);
    return response;
  }

  getEmailRenderer() {
    CommonUtil.debug("DefaultProjectLayer.getEmailRenderer returning DefaultEmailRenderer");
    return new DefaultEmailRenderer();
  }

  getPdfCodes(surveyInstance: SurveyInstance): Array<PdfCode> {
    return [];
  }
}
