import React from "react";
import { ConditionalItem } from "@careerinsight/applib-common/entity";
import { SurveyPage } from "../../components";
import { SurveyContext } from "../../providers";

export const ConditionalWidget: React.FC<{ item: ConditionalItem }> = ({ item }) => {
  // TODO: !!! Sanitize conditionalItem.condition
  const { answers } = React.useContext(SurveyContext);
  if (!Function("return " + item.condition).apply(answers)) return null;

  return <SurveyPage page={item} />;
};
