import React, { useContext, useEffect, useState } from "react";
import { SurveyContext, ValidationContext } from "../../providers";
import { PercentageBar, SurveyError, SurveyFinished, SurveyFooter, SurveyLoading, SurveyPage } from "../index.js";
import { appSettings } from "../../app-settings";

export const SurveyApp: React.FC<{ children: React.ReactNode; pageFooter?: React.JSX.Element }> = ({
  children,
  pageFooter,
}) => {
  const [isFinished, setIsFinished] = useState(false);

  const {
    surveyDefinition,
    flushPendingAnswers,
    hasPendingAnswers,
    isLoading,
    error,
    answers,
    setSurveyComplete,
    pageNumber,
    previousPage,
    nextPage,
  } = useContext(SurveyContext);

  const { validationState, setShowValidation, showValidation } = useContext(ValidationContext);

  // Warn the user if they have unsaved data
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasPendingAnswers()) {
        flushPendingAnswers();
        event.preventDefault();
        event.returnValue = "true";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasPendingAnswers]);

  /**
   * Click the finish button on the final page
   */
  function finishSurvey() {
    setSurveyComplete();
    flushPendingAnswers();
    setIsFinished(true);
  }

  /**
   * Navigate to the next page
   */
  function previousPageClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowValidation(false);
    previousPage();
  }

  /**
   * Navigate to the next page
   */
  function nextPageClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowValidation(false);

    // Only proceed if the current page is valid
    let isPageValid = true;

    for (const questionKey in validationState) {
      isPageValid = isPageValid && validationState[questionKey]!;
    }

    if (isPageValid) {
      const isLastPage = nextPage();
      if (isLastPage) setSurveyComplete();
    } else setShowValidation(true);
  }

  let appChildren: React.ReactNode;
  let pageCount = 0;
  let showFooter = false;

  if (isFinished) {
    // Show the finish page
    appChildren = (
      <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
        <div className="p-6 space-y-4">
          <SurveyFinished />
        </div>
      </div>
    );
  } else if (isLoading) {
    // Show the loading spinner
    appChildren = (
      <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
        <div className="p-6 space-y-4">
          <SurveyLoading />
        </div>
      </div>
    );
  } else if (surveyDefinition.length) {
    // Show the survey
    const page = surveyDefinition[pageNumber]!;
    pageCount = surveyDefinition.length;
    showFooter = true;

    appChildren = (
      <>
        <div className="pb-3">
          <PercentageBar />
        </div>

        <div key={page.uniqueId} className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
          <div className="py-3 px-1 sm:px-6 space-y-4">
            {showValidation && (
              <div className="border border-danger-border text-danger-dark bg-danger-light rounded-md mb-5 p-2 text-sm flex">
                <div className="self-center">
                  <svg width="18px" fill="currentColor" viewBox="0 0 16 16" className="ml-1">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </div>
                <div className="self-center ps-2 text-danger-dark">
                  Some questions are mandatory and should be completed before you can progress
                </div>
              </div>
            )}

            <SurveyPage page={page} />
          </div>
        </div>
      </>
    );
  } else {
    // Show the error screen
    const errorMessage = error?.message ? error?.message : "Unknown error";
    appChildren = (
      <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
        <div className="p-6 space-y-4">
          <SurveyError errorMessage={errorMessage} />
        </div>
      </div>
    );
  }

  return (
    <div className="sm:bg-background">
      <div className="max-w-4xl mx-auto">
        <div className="min-h-screen sm:pt-3 pb-12 mx-3">
          <div className="pb-3">
            {/* Survey header - passed in as children */}
            {children}
          </div>
          <div className="pb-3">{appChildren}</div>

          {showFooter && (
            <div className="pb-3">
              <SurveyFooter
                pageNumber={pageNumber}
                previousPage={previousPageClick}
                nextPage={nextPageClick}
                pageCount={pageCount}
                finishSurvey={finishSurvey}
              />
            </div>
          )}

          {appSettings.showAnswers && (
            <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder mb-6">
              <div className="p-6 space-y-4">
                <h1 className="text-primary text-3xl font-bold">Answers</h1>
                <pre>{JSON.stringify(answers, null, 2)}</pre>

                <h1 className="text-primary text-3xl font-bold">Validation State</h1>
                <pre>{JSON.stringify(validationState, null, 2)}</pre>
              </div>
            </div>
          )}

          {answers.SYS_PREVIEW_MODE === true && (
            <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
              <div className="p-6 space-y-4">
                <h1 className="text-primary text-3xl font-bold">Preview mode</h1>
                <p>Responses will not be saved to the database</p>
              </div>
            </div>
          )}

          {pageFooter && pageFooter}
        </div>
      </div>
    </div>
  );
};

export default SurveyApp;
