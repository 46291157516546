import { Answers as AnswersType, SurveyPage as SurveyPageType } from "@careerinsight/applib-common/entity";
import { getErrorMessage } from "../util.js";
import { appSettings } from "../index";

export async function fetchSurveyAndResponse(surveyInstanceSecretKey: string) {
  const response = await fetch(appSettings.surveyApi + "/surveys/" + surveyInstanceSecretKey);

  if (response.ok) {
    const responseJson = await response.json();
    const surveyDefinition = responseJson.surveyDefinition as SurveyPageType[];
    const surveyResponse = responseJson.surveyResponse as AnswersType;
    return { surveyDefinition: surveyDefinition, surveyResponse };
  }

  throw new Error(getErrorMessage(await response.json()));
}
