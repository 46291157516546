import { SurveyItem, SurveyPage } from "./entity";

/**
 * Run 'lambda' on SurveyItem, and every child SurveyItem should it have any
 */
export function applyToItemAndChildren(lambda: (item: SurveyItem) => void, item: SurveyItem) {
  // Run the user-supplied lambda
  lambda(item);

  // Recurse if necessary
  if (item.type === "conditional") {
    for (const innerItem of item.surveyItems) {
      applyToItemAndChildren(lambda, innerItem);
    }
  }
}

/**
 * Applies the supplied function to every SurveyItem in pages
 */
export function applyToAllItems(
  lambda: (item: SurveyItem) => void,
  surveyPages: SurveyPage[],
  pageLambda?: (page: SurveyPage) => void,
) {
  for (const page of surveyPages) {
    if (pageLambda) pageLambda(page);
    for (const item of page.surveyItems) {
      applyToItemAndChildren(lambda, item);
    }
  }
}
