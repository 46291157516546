import React from "react";
import { SurveyItem } from "@careerinsight/applib-common/entity";
import { getStandardWidget } from "../../widgets";
import { appSettings } from "../../app-settings";

const SurveyQuestion: React.FC<{ item: SurveyItem }> = ({ item }) => {
  // Project-specific widgets
  if (appSettings.getWidget && appSettings.getWidget(item)) return appSettings.getWidget(item);

  // Standard widgets
  return getStandardWidget(item);
};

export default SurveyQuestion;
