import React from "react";
import { SurveyBaseItem } from "@careerinsight/applib-common/entity";

export const UnknownWidget: React.FC<{ item: SurveyBaseItem }> = ({ item }) => {
  return (
    <li className="block">
      <div className="border border-danger-border rounded-md p-2 text-danger bg-danger-light text-sm">
        <div className="flex gap-2">
          <div className="font-bold">Error rendering item {item.uniqueId}</div>
        </div>

        <div>
          Unknown survey element type <code>'{item.type}'</code>
        </div>
      </div>
    </li>
  );
};
