import React from "react";
import { ConditionalItem, SurveyPage as SurveyPageType } from "@careerinsight/applib-common/entity";
import SurveyQuestion from "../SurveyQuestion/SurveyQuestion.js";

export const SurveyPage: React.FC<{ page: SurveyPageType | ConditionalItem }> = ({ page }) => {
  return (
    <ol className="flex flex-col gap-6 my-3">
      {page.surveyItems.map((question) => (
        <SurveyQuestion key={question.uniqueId} item={question} />
      ))}
    </ol>
  );
};
