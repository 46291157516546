import React, { useEffect } from "react";
import { NetPromoterQuestion } from "@careerinsight/applib-common/entity";
import { SurveyContext, ValidationContext } from "../../providers";
import { QuestionLabel } from "../../components";

export const NetPromoterWidget: React.FC<{ question: NetPromoterQuestion }> = ({ question }) => {
  const { answers, setAnswer } = React.useContext(SurveyContext);
  const { setValid, removeValid, showValidation } = React.useContext(ValidationContext);
  const a: number =
    answers[question.questionKey] || answers[question.questionKey] === 0 ? Number(answers[question.questionKey]) : -1;
  const [state, setState] = React.useState<number>(a);
  const scores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // See SelectWidget for comments on how validation works
  function isValid() {
    if (!question.required) return true;
    return answers[question.questionKey] !== undefined;
  }

  useEffect(() => {
    setValid(question.uniqueId, isValid());
    return () => removeValid(question.uniqueId);
  }, [answers]);

  const invalid = showValidation && !isValid();

  return (
    <li className="block">
      <QuestionLabel question={question} />

      {/*Desktop*/}
      <div className="hidden sm:block">
        <div className="flex" role="group">
          {scores.map((num, index) => (
            <button
              className={`
								border-t border-b border-s p-2 grow hover:brightness-[60%]
								${invalid ? "border-danger bg-danger-light/50 text-danger" : num === state ? "text-light border-primary bg-primary" : "border-secondary-border bg-secondary-light/20"}
								${index === 0 ? "rounded-s-md" : ""}
								${index === scores.length - 1 ? "rounded-e-md border-e" : ""}
							`}
              type="button"
              key={num}
              onClick={() => {
                setState(num);
                setAnswer(question, num);
              }}
            >
              {num}
            </button>
          ))}
        </div>

        <div className={`flex text-sm ${invalid ? "text-danger" : "text-secondary"}`}>
          <div className="grow">Not at all likely</div>
          <div className="grow text-end">Very likely</div>
        </div>

        {invalid && <small className="text-danger font-semibold">Please select a value</small>}
      </div>

      {/*Mobile*/}
      <select
        className={`
				bg-secondary-light/20 border rounded-md block w-full p-2 sm:hidden
				${invalid ? "border-danger text-danger" : "border-secondary-border"}
				`}
        id={question.uniqueId}
        value={state}
        onChange={(event) => {
          setState(Number(event.target.value));
          setAnswer(question, event.target.value);
        }}
      >
        <option value="-1" disabled>
          Please select
        </option>

        {scores.map((num) => (
          <option key={num} value={num}>
            {num}
            {num === 0 && " - not at all likely"}
            {num === 10 && " - very likely"}
          </option>
        ))}
      </select>

      {invalid && <small className="text-danger font-semibold sm:hidden">Please select a value</small>}
    </li>
  );
};
