import React from "react";
import { QuestionLabel, SurveyContext } from "@careerinsight/applib-react";
import { CustomMajorMinorFactor } from "@careerinsight/ppg-project-layer";

/**
 * Collapsible set of related MajorMinorFactor items
 */
export const CustomMajorMinorFactorWidget: React.FC<{ question: CustomMajorMinorFactor }> = ({ question }) => {
  const { answers, setAnswer } = React.useContext(SurveyContext);
  const textFieldQuestionKey = question.questionKey + "Text";
  const ratingQuestionKey = question.questionKey + "Rating";
  const a: string = answers[textFieldQuestionKey] ? (answers[textFieldQuestionKey] as string) : "";
  const [state, setState] = React.useState<string>(a);
  const maxLength = 1024;

  return (
    <li className="block">
      <QuestionLabel question={question} />

      <div className="sm:flex items-center py-2">
        {/* Text field */}
        <input
          maxLength={maxLength}
          className="border rounded-md block w-full p-2 me-4 bg-secondary-light/20 border-secondary-border"
          id={question.uniqueId}
          value={state}
          onChange={(event) => {
            setState(event.target.value);
            setAnswer({ ...question, questionKey: textFieldQuestionKey }, event.target.value);
          }}
        />

        {/* Major/minor buttons */}
        <div className="inline-flex rounded-md shadow-sm w-full sm:w-auto" role="group">
          <button
            type="button"
            className={`grow px-4 py-2 text-sm font-medium border rounded-s-lg ${
              answers[ratingQuestionKey] === "major" ? "bg-primary" : "hover:bg-secondary-light"
            }`}
            onClick={() => setAnswer({ ...question, questionKey: ratingQuestionKey }, "major")}
          >
            <span className={answers[ratingQuestionKey] === "major" ? "text-light" : ""}>Major</span>
          </button>

          <button
            type="button"
            className={`grow px-4 py-2 text-sm font-medium border-y ${
              answers[ratingQuestionKey] === "minor" ? "bg-primary" : "hover:bg-secondary-light"
            }`}
            onClick={() => setAnswer({ ...question, questionKey: ratingQuestionKey }, "minor")}
          >
            <span className={answers[ratingQuestionKey] === "minor" ? "text-light" : ""}>Minor</span>
          </button>

          <button
            type="button"
            className="p-2 text-sm font-medium border rounded-e-lg hover:bg-secondary-light"
            onClick={() => setAnswer({ ...question, questionKey: ratingQuestionKey }, "")}
          >
            <div className="text-secondary">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18">
                <path
                  fill="currentColor"
                  d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </li>
  );
};
